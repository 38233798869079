/* src/components/Navbar.css */

.navbar {
    display: flex;
    padding:20px;
    justify-content: space-between;
    align-items: center;
    background-color: white;/* Partial transparency */
    padding: 5px;
    color: white;
    position: sticky;
    /* top: 0; */
    z-index: 1000;
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  
  .nav-logo {
    display: flex;
    align-items: center;
    mix-blend-mode: multiply;
  }
  
  .logo-img {
    height: 40px; /* Adjust height as needed */
    width: auto;  /* Maintain aspect ratio */
    border-radius: 8px;
  }
  
  .nav-links {
    display: flex;
    gap: 1rem;
  }
  
  .nav-links a {
    color: #555;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .nav-links a:hover {
    background-color: #949292;
  }
  
  .nav-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 0.5rem;
  }
  
  .nav-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: #7e7c7c;
  }
  
  @media (max-width: 768px) {
    .navbar {
      background-color: #f0f0f0; /* Partial transparency */
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 60px;
      left: 0;
      background-color: #f0f0f0; /* Match transparency of the navbar */
      padding: 1rem;
      transition: display 0.3s;
    }
  
    .nav-links.open {
      display: flex;
    }
  
    .nav-toggle {
      display: flex;
    }
  }
  