/* Footer.css */
.footer {
    background-color: #fff;
    color: #333;
    padding: 20px;
  }
  
  .footer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .footer-item {
    padding: 10px;
  }
  
  .footer-item h3 {
    margin-bottom: 10px;
  }
  
  .footer-item p, .footer-item ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .footer-item ul li {
    margin-bottom: 10px;
  }
  
  .footer-item ul li a {
    color: #333;
    text-decoration: none;
  }
  
  .footer-item ul.social-media {
    display: flex;
    flex-direction: column;
  }
  
  .footer-item ul.social-media li {
    margin-bottom: 10px;
  }
  
  .footer-item form {
    display: flex;
    flex-direction: column;
  }
  
  .footer-item form input {
    padding: 10px;
    margin-bottom: 10px;
    border: solid black 2px;
    border-radius: 4px;
  }
  
  .footer-item form button {
    padding: 10px;
    border: solid black 1px;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
  }
  
  .footer-item form button:hover {
    background-color: #c6c4c4;
  }
  
  @media (max-width: 768px) {
    .footer-grid {
      grid-template-columns: 1fr;
    }
  }
  