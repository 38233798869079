.carousel-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  z-index: 1; 
  margin-top: 20px;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  

}

.carousel-item img {
  max-width: 100%; 
  height: auto;
  border-radius: 8px;
  
}
