@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');


.App {
  text-align: center;
   /* Adjust this value as needed */
  margin: 0 auto; /* Centers the container */
  padding: 0 15px; /* Adds some padding on the sides */
}

.content-wrapper {
  margin-top: 5px; /* Ensure there's space below the sticky navbar */
}

.carousel-container {
  padding: 2px;
  max-width: 100%;
  height:70%;
  background-color: #f0f0f0;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  margin-top: 5px; /* Ensure proper spacing */
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 10px 20px;  /* Adjust margin to control gap between images */
}

.carousel-item img {
  max-width: 80%; /* Reduce max-width to make images smaller */
  height: auto;
  border-radius: 8px;
  max-height: 200px; /* Limit height to make images smaller */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.centered-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0; /* Add margin to space out from the carousel and slider */
}

.centered-image {
  max-width: 80%;
  height: auto;
  border-radius: 8px;
}


.section-heading {
  text-align: center;
  font-size: 32px; /* Increase font size */
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  padding: 0 20px; /* Space for the horizontal line effect */
  background: #f9f9f9;
  font-family: 'Lobster', cursive; /* Apply Lobster font */
}

.section-heading::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px; /* Thickness of the line */
  background: #333; /* Color of the line */
  z-index: -1; /* Place line behind the heading */
}

.image-slider {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  margin-top: 20px; /* Ensure slider is below the centered image */
  scroll-behavior: smooth; /* Smooth scrolling */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.image-slider::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.image-slider-track {
  display: flex;
  gap: 5px; /* Adjust gap between images */
  padding: 0 5px; /* Reduced padding to bring images closer to edges */
}

.slider-image {
  flex: 0 0 auto; /* Prevent images from shrinking or growing */
  max-width: 150px; /* Set a maximum width for images */
  height: auto;
  border-radius: 8px;
  object-fit: cover; /* Ensure images cover the area without distortion */
}

.content {
  padding: 20px;
}

@media (max-width: 768px) {
  .carousel-container {
    padding: 10px;
  }

  .centered-image-container {
    margin: 10px 0;
  }

  .image-slider {
    padding: 5px 0;
  }

  .slider-image {
    max-width: 120px; /* Adjust image width for smaller screens */
  }

  .content {
    padding: 10px;
  }
}
