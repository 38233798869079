/* CompanyLogos.css */
.company-logos {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .company-logos h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .logos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 20px;
    justify-content: center;
  }
  
  .logo-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .logo-item img {
    max-width: 100%;
    height: auto;
    max-height: 80px; /* Limit the height of the logo */
  }
  
  @media (max-width: 768px) {
    .logo-item {
      padding: 5px;
    }
  }
  